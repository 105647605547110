/*
 * Sidebar
*/
.sidebar {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	max-height: 100%;
	overflow-y: auto;
	width: 100%;
	@include transition(ease-out .1s);
	
	@include media-breakpoint-up(md) {
		width: 100%;
		max-width: 260px;
	}

	.nav {
		white-space: nowrap;
	}

	.nav-item {
		&.active {
			& > .nav-link {
				color: $light;
				background-color: #2e3650;
			}
		}
		.nav-link {
			color: $light;
			cursor: pointer;
			&:hover {
				color: $light;
				background-color: #2e3650;
			}
		}
		margin-bottom: .2rem;
	}

	.nav-link + .multi-level {
		margin-top: .2rem;
	}

	.nav-link {
		font-size: $font-size-base;
		vertical-align: middle;
		padding: 0.55rem 0.75rem;
		@include border-radius($border-radius);

		.sidebar-icon {
			margin-right: .5rem;
			color: $white;
			span{
				min-width: 22px;
			}
		}

		.link-arrow{
			font-size: $font-size-sm;
		}

		&[data-bs-toggle=collapse][aria-expanded=true] .link-arrow{
			@include transform(rotate(90deg));
			transition: $transition-base;
		}
	}
	.nav-link.active {
		color: #363c54;
	}

	.multi-level{
		.nav-link{
			padding-left: 45px;
		}
	}

	.sidebar-text,
	.link-arrow,
	.badge,
	.notification-count {
		opacity: 1;
		@include transition(opacity .3s);
	}

}

.sidebar-inner {
	position: relative;
	overflow-y: hidden;
}

.sidebar-heading {
	font-size: .75rem;
	text-transform: uppercase;
}

.user-card{
	border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
	.sidebar {
		width: 100%;
	}
}

.content {

	overflow: visible;
	padding: 0 $spacer 0 $spacer;
	@include transition(all .3s);

	@include media-breakpoint-up(md) {
		margin-left: 260px;
	}
}

.sidebar-toggle {
	font-size: 1.3rem;

	&:hover,
	&:focus {
		outline: none;
		box-shadow: none;
		background: $gray-400;
	}

}